import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import { SwiperModule } from 'swiper/angular';

import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { IonSlides } from '@ionic/core/components';

import { HestiaUserFacade } from '@hestia/ngx-user';

import { environment } from '../../../environments/environment';
import { LoginFormComponent } from '../../components/login-form';
import { NavComponent } from '../../components/nav';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  standalone: true,
  selector: 'epenat-landing',
  imports: [FormsModule, ReactiveFormsModule, IonicModule, SwiperModule],
  templateUrl: 'landing.page.html',
  styles: [
    `
      swiper {
        height: 100%;
        --bullet-background-active: #fff;
      }

      ion-text {
        color: #fff;
        padding: 1rem;
        width: 600px;
      }

      h2 {
        font-weight: 600;
        font-size: 1.25rem;
        margin-top: 1rem;
      }

      ul {
        list-style-type: disc;
        margin-left: 0rem;
        text-align: left;
      }

      p,
      li {
        font-size: 80%;
      }

      p {
        margin-top: 0;
      }

      img.ScreenShot {
        width: 30% !important;
        border: 2px solid white;
      }

      img {
        width: 60% !important;
        margin: 16px;
        border-radius: 10px;
      }
    `,
  ],
})
export class LandingPage {
  public slides: IonSlides;

  credentialsForm = new FormGroup({
    login: new FormControl(environment?.testUsername, [Validators.minLength(3), Validators.required]),
    password: new FormControl(environment?.testPassword, [Validators.minLength(5), Validators.required]),
  });

  constructor(private modalController: ModalController, private userFacade: HestiaUserFacade) {}

  setSwiperInstance(swiper: IonSlides) {
    this.slides = swiper;
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: NavComponent,
      componentProps: {
        root: LoginFormComponent,
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.userFacade.initLogin(data.login, data.password);
    }
  }
}
