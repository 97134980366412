import OneSignal from 'onesignal-cordova-plugin';

import { Component, OnInit } from '@angular/core';

import { AlertController } from '@ionic/angular';

import { Capacitor } from '@capacitor/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { HestiaFhirUsersService } from '@hestia/ngx-fhir';
import { AgeGroup } from '@hestia/ngx-types';
import { HestiaUserFacade, userActions } from '@hestia/ngx-user';

import { update } from './store/+state/epenat-specific.actions';

@Component({
  selector: 'epenat-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;
  userState$ = this.userFacade.userState$;

  penatAgeGroup = AgeGroup;

  public appPages = [
    { title: 'App', url: '/tabs', icon: 'id-card' },
    // { title: 'Support', url: '/support', icon: 'help-circle' },
    { title: 'Privacy Policy', url: '/privacy-policy', icon: 'document-text' },
  ];

  constructor(
    public userFacade: HestiaUserFacade,
    private userService: HestiaFhirUsersService,
    private alertController: AlertController,
    private store: Store
  ) {
    if (Capacitor.isNativePlatform()) {
      OneSignal.Debug.setLogLevel(6);
      OneSignal.initialize('5b0fb90c-f3ce-486b-b85d-6a19ae470695');

      OneSignal.Notifications.addEventListener('click', async (e) => {
        let clickData = await e.notification;
        console.log('Notification Clicked : ' + clickData);
      });

      OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
        console.log('Notification permission granted ' + success);
      });
    }
  }

  ngOnInit() {
    this.isAuthenticated$ = this.userFacade.isUserAuthenticated$();
  }

  async handleAgeGroupChange(event) {
    try {
      this.userService
        .patchUser({
          ageGroup: event.detail.value,
        })
        .subscribe(() => this.store.dispatch(userActions.updateUser({ ageGroup: event.detail.value })));
    } catch (e) {
      console.log(e);
    }
  }

  async presentLogoutAlert() {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to sign out?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => this.store.dispatch(userActions.logoutFixed()),
        },
      ],
    });

    await alert.present();
  }
}
