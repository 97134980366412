import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IChemoBlockDay } from './chemo-block-day.model';

export const loadChemoBlockDays = createAction(
  '[ChemoBlockDay/API] Load ChemoBlockDays',
  props<{ chemoBlockDays: IChemoBlockDay[] }>()
);

export const addChemoBlockDay = createAction(
  '[ChemoBlockDay/API] Add ChemoBlockDay',
  props<{ chemoBlockDay: IChemoBlockDay }>()
);

export const upsertChemoBlockDay = createAction(
  '[ChemoBlockDay/API] Upsert ChemoBlockDay',
  props<{ chemoBlockDay: IChemoBlockDay }>()
);

export const addChemoBlockDays = createAction(
  '[ChemoBlockDay/API] Add ChemoBlockDays',
  props<{ chemoBlockDays: IChemoBlockDay[] }>()
);

export const upsertChemoBlockDays = createAction(
  '[ChemoBlockDay/API] Upsert ChemoBlockDays',
  props<{ chemoBlockDays: IChemoBlockDay[] }>()
);

export const updateChemoBlockDay = createAction(
  '[ChemoBlockDay/API] Update ChemoBlockDay',
  props<{ chemoBlockDay: Update<IChemoBlockDay> }>()
);

export const updateChemoBlockDays = createAction(
  '[ChemoBlockDay/API] Update ChemoBlockDays',
  props<{ chemoBlockDays: Update<IChemoBlockDay>[] }>()
);

export const deleteChemoBlockDay = createAction(
  '[ChemoBlockDay/API] Delete ChemoBlockDay',
  props<{ id: string }>()
);

export const deleteChemoBlockDays = createAction(
  '[ChemoBlockDay/API] Delete ChemoBlockDays',
  props<{ ids: string[] }>()
);

export const clearChemoBlockDays = createAction(
  '[ChemoBlockDay/API] Clear ChemoBlockDays'
);

export const resetChemoBlockDays = createAction(
  '[ChemoBlockDay/API] Reset ChemoBlockDays'
);
