import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MedicineUse } from './medicine-use.model';
import * as MedicineUseActions from './medicine-use.actions';
import {
  ChemoBlockDayNumber,
  ChemoBlockNumber,
  DrugName,
} from '../../models/types';

export const medicineUsesFeatureKey = 'medicineUses';

export interface MedicineUsesState extends EntityState<MedicineUse> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MedicineUse> = createEntityAdapter<MedicineUse>();

export const initialState: MedicineUsesState = adapter.getInitialState({
  // additional entity state properties
});

export const medicineUsesReducer = createReducer(
  initialState,
  on(MedicineUseActions.addMedicineUse, (state, action) =>
    adapter.addOne(action.medicineUse, state)
  ),
  on(MedicineUseActions.upsertMedicineUse, (state, action) =>
    adapter.upsertOne(action.medicineUse, state)
  ),
  on(MedicineUseActions.addMedicineUses, (state, action) =>
    adapter.addMany(action.medicineUses, state)
  ),
  on(MedicineUseActions.upsertMedicineUses, (state, action) =>
    adapter.upsertMany(action.medicineUses, state)
  ),
  on(MedicineUseActions.updateMedicineUse, (state, action) =>
    adapter.updateOne(action.medicineUse, state)
  ),
  on(MedicineUseActions.updateMedicineUses, (state, action) =>
    adapter.updateMany(action.medicineUses, state)
  ),
  on(MedicineUseActions.deleteMedicineUse, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(MedicineUseActions.deleteMedicineUses, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(MedicineUseActions.loadMedicineUses, (state, action) =>
    adapter.setAll(action.medicineUses, state)
  ),
  on(MedicineUseActions.clearMedicineUses, (state) => adapter.removeAll(state))
);

export const selectMedicineUsesState = createFeatureSelector<MedicineUsesState>(
  medicineUsesFeatureKey
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectMedicineUsesState);

export const selectMedicineUsesByChemoBlockDay = (
  chemoBlock: ChemoBlockNumber,
  day: ChemoBlockDayNumber
) =>
  createSelector(selectAll, (uses) =>
    uses.filter(
      (elem) =>
        elem.chemoBlockNumber === chemoBlock && elem.chemoBlockDayNumber === day
    )
  );

export const selectMedicineUsesyChemoBlockDayAndDrug = (
  chemoBlock: ChemoBlockNumber,
  day: ChemoBlockDayNumber,
  drug: DrugName
) =>
  createSelector(selectAll, (uses) =>
    uses.filter(
      (elem) =>
        elem.chemoBlockNumber === chemoBlock &&
        elem.chemoBlockDayNumber === day &&
        elem.drugName === drug
    )
  );
