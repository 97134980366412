import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { MedicineUse } from './medicine-use.model';

export const loadMedicineUses = createAction(
  '[MedicineUse/API] Load MedicineUses', 
  props<{ medicineUses: MedicineUse[] }>()
);

export const addMedicineUse = createAction(
  '[MedicineUse/API] Add MedicineUse',
  props<{ medicineUse: MedicineUse }>()
);

export const upsertMedicineUse = createAction(
  '[MedicineUse/API] Upsert MedicineUse',
  props<{ medicineUse: MedicineUse }>()
);

export const addMedicineUses = createAction(
  '[MedicineUse/API] Add MedicineUses',
  props<{ medicineUses: MedicineUse[] }>()
);

export const upsertMedicineUses = createAction(
  '[MedicineUse/API] Upsert MedicineUses',
  props<{ medicineUses: MedicineUse[] }>()
);

export const updateMedicineUse = createAction(
  '[MedicineUse/API] Update MedicineUse',
  props<{ medicineUse: Update<MedicineUse> }>()
);

export const updateMedicineUses = createAction(
  '[MedicineUse/API] Update MedicineUses',
  props<{ medicineUses: Update<MedicineUse>[] }>()
);

export const deleteMedicineUse = createAction(
  '[MedicineUse/API] Delete MedicineUse',
  props<{ id: string }>()
);

export const deleteMedicineUses = createAction(
  '[MedicineUse/API] Delete MedicineUses',
  props<{ ids: string[] }>()
);

export const clearMedicineUses = createAction(
  '[MedicineUse/API] Clear MedicineUses'
);
