import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { MarkdownModule } from 'ngx-markdown';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { HestiaNgrxLocalStorageSyncModule } from '@hestia/ngrx-local-storage-sync';
import { HestiaCommonModule } from '@hestia/ngx-common';
import { HestiaNgxFhirModule, fhirResourcesFeatureKey } from '@hestia/ngx-fhir';
import {
  NgxFhirQnrFormsModule,
  QnrSessionServerSaveStrategy,
  defaultTranslations,
  qnrFormSessionFeatureKey,
  qnrModuleSettingsFeatureKey,
} from '@hestia/ngx-fhir-qnr-forms';
import { AuthGuard, AuthNoneGuard, HestiaNgxUserModule, userFeatureKey } from '@hestia/ngx-user';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EpenatRoutes } from './models/types';
import { reducers } from './store';
import { ChemoBlockDayEffects } from './store/+chemo-block-day/chemo-block-day.effects';
import { chemoBlockDaysFeatureKey } from './store/+chemo-block-day/chemo-block-day.reducer';
import { MedicineUseEffects } from './store/+medicine-use/medicine-use.effects';
import { medicineUsesFeatureKey } from './store/+medicine-use/medicine-use.reducer';
import { QnrAnswerEffects } from './store/+qnr-answer/qnr-answer.effects';
import { epenatQnrAnswersFeatureKey } from './store/+qnr-answer/qnr-answer.reducer';
import { EpenatSpecificEffects } from './store/+state/epenat-specific.effects';
import { epenatSpecificFeatureKey } from './store/+state/epenat-specific.reducer';
import { VomitEventEffects } from './store/+vomit-event/vomit-event.effects';
import { vomitEventsFeatureKey } from './store/+vomit-event/vomit-event.reducer';
import { TemplatePageTitleStrategy } from './title-strategy';
import { TranslocoRootModule } from './transloco-root.module';

const guards = [AuthNoneGuard, AuthGuard];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({}),
    AppRoutingModule,
    HestiaCommonModule.forRoot(environment),
    TranslocoRootModule,
    MarkdownModule.forRoot(),
    NgxFhirQnrFormsModule.forRoot({
      config: {
        enableWhenFunctions: {},
        enableDebugMode: false,
        rootPath: '/',
        globalMarkdown: true,
        qnrPageRoutePath: EpenatRoutes.QuestionnairePage,
        serverSaveStrategy: QnrSessionServerSaveStrategy.OnFinalPageSubmit,
        enableValueHistoryExt: true,
      },
      translations: defaultTranslations,
      userSettingsInitalState: {
        enableVirtualKeyboard: false,
      },
    }),
    StoreModule.forRoot(reducers, {
      initialState: {
        [environment.syncHydratedKey]: false,
      },
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    HestiaNgxUserModule.forRoot({
      syncHydratedKey: environment.syncHydratedKey,
      isAuthenticatedGuardFailureRoute: environment.isAuthenticatedGuardFailureRoute,
      isUnauthenticatedGuardFailureRoute: environment.isUnauthenticatedGuardFailureRoute,
    }),
    HestiaNgxFhirModule.forRoot({
      initialResources: [],
    }),
    HestiaNgrxLocalStorageSyncModule.forRoot({
      storageKey: environment.syncStorageKey,
      syncProvider: 'local-forage',
      syncSettings: {
        keys: [
          userFeatureKey,
          qnrModuleSettingsFeatureKey,
          qnrFormSessionFeatureKey,
          fhirResourcesFeatureKey,
          epenatSpecificFeatureKey,
          chemoBlockDaysFeatureKey,
          medicineUsesFeatureKey,
          epenatQnrAnswersFeatureKey,
          vomitEventsFeatureKey,
        ],
        ignoreActions: [],
        hydratedStateKey: environment.syncHydratedKey, // Add this key to the state
      },
    }),
    EffectsModule.forRoot([
      EpenatSpecificEffects,
      QnrAnswerEffects,
      MedicineUseEffects,
      ChemoBlockDayEffects,
      VomitEventEffects,
    ]),
  ],
  providers: [
    ...guards,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
    OneSignal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
