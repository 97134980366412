import { isBefore, parseISO } from 'date-fns';

import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import { ChemoBlockDayNumber, ChemoBlockNumber } from '../../models/types';
import * as VomitEventActions from './vomit-event.actions';
import { VomitEvent } from './vomit-event.model';

export const vomitEventsFeatureKey = 'vomitEvents';

export interface VomitEventsState extends EntityState<VomitEvent> {
  // additional entities state properties
}

export const adapter: EntityAdapter<VomitEvent> =
  createEntityAdapter<VomitEvent>();

export const initialState: VomitEventsState = adapter.getInitialState({
  // additional entity state properties
});

export const vomitEventsReducer = createReducer(
  initialState,
  on(VomitEventActions.addVomitEvent, (state, action) =>
    adapter.addOne(action.vomitEvent, state)
  ),
  on(VomitEventActions.upsertVomitEvent, (state, action) =>
    adapter.upsertOne(action.vomitEvent, state)
  ),
  on(VomitEventActions.addVomitEvents, (state, action) =>
    adapter.addMany(action.vomitEvents, state)
  ),
  on(VomitEventActions.upsertVomitEvents, (state, action) =>
    adapter.upsertMany(action.vomitEvents, state)
  ),
  on(VomitEventActions.updateVomitEvent, (state, action) =>
    adapter.updateOne(action.vomitEvent, state)
  ),
  on(VomitEventActions.updateVomitEvents, (state, action) =>
    adapter.updateMany(action.vomitEvents, state)
  ),
  on(VomitEventActions.deleteVomitEvent, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(VomitEventActions.deleteVomitEvents, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(VomitEventActions.loadVomitEvents, (state, action) =>
    adapter.setAll(action.vomitEvents, state)
  ),
  on(VomitEventActions.clearVomitEvents, (state) => adapter.removeAll(state))
);

export const selectVomitEventsState = createFeatureSelector<VomitEventsState>(
  vomitEventsFeatureKey
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectVomitEventsState);

export const selectVomitEventsByChemoBlockDay = (
  chemoBlock: ChemoBlockNumber,
  day: ChemoBlockDayNumber
) =>
  createSelector(selectAll, (vomitEvents) =>
    vomitEvents
      .filter(
        (elem) =>
          elem.chemoBlockNumber === chemoBlock &&
          elem.chemoBlockDayNumber === day
      )
      .sort((a, b) =>
        isBefore(parseISO(a.timestamp), parseISO(b.timestamp)) ? -1 : 1
      )
  );
