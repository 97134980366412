import * as vomitEventActions from './vomit-event.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { userActions } from '@hestia/ngx-user';

@Injectable()
export class VomitEventEffects {
  constructor(private actions$: Actions) {}

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.logout),
      mergeMap(() => of(vomitEventActions.clearVomitEvents()))
    );
  });
}
