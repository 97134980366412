/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { concat, EMPTY, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  qnrFormSessionActions,
  selectQnrFormSessionById,
} from '@hestia/ngx-fhir-qnr-forms';
import { userActions } from '@hestia/ngx-user';

import { EpenatQnrId, EpenatRoutes } from '../../models/types';
import * as qnrAnswerActions from './qnr-answer.actions';
import { EpenatQnrAnswer } from './qnr-answer.model';

@Injectable()
export class QnrAnswerEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store
  ) {}

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.logout),
      mergeMap(() => of(qnrAnswerActions.clearQnrAnswers()))
    );
  });

}
