<ion-header class="ion-no-border" *ngIf="fhirUser$ | async as user">
  <ng-container *ngIf="fhirUser$ | async as user">
    <ion-toolbar color="light">
      <ion-title>
        {{user.username}} - Age group: ({{ageGroupLabel(user.ageGroup)}})
      </ion-title>

      <ion-buttons slot="start">
        <ion-back-button defaultHref="/admin" color="primary"></ion-back-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="light">
      <ion-segment
        [(ngModel)]="currentSegment"
        [value]="fullQnrId(qnrId.EPENAT_NAUSEA_WORDS_4_TO_18)"
        color="primary"
      >
        <ion-segment-button
          [value]="fullQnrId(qnrId.EPENAT_NAUSEA_WORDS_4_TO_18)"
        >
          <ion-label>Definitions</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="fullQnrId(qnrId.EPENAT_VOMIT_TIME)">
          <ion-label>Vomiting</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="fullQnrId(qnrId.EPENAT_MEDICINE)">
          <ion-label>Medicine</ion-label>
        </ion-segment-button>
        <ion-segment-button
          *ngIf="user.ageGroup==='FOUR_TO_EIGHT'"
          [value]="fullQnrId(qnrId.EPENAT_4_TO_8)"
        >
          <ion-label>Nausia</ion-label>
        </ion-segment-button>
        <ion-segment-button
          *ngIf="user.ageGroup==='NINE_TO_EIGHTEEN'"
          [value]="fullQnrId(qnrId.EPENAT_9_TO_18)"
        >
          <ion-label>Nausia</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </ng-container>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col
        [size]="12"
        *ngIf="fhirQuestionnaireResponses$ | async as responses"
      >
        <table>
          <tr>
            <th>Questionnaire</th>
            <th>Date</th>
            <th>Response</th>
          </tr>
          <ng-container *ngFor="let response of responses">
            <tr *ngIf="response.questionnaire === currentSegment">
              <td>{{ getQuestionnaireName(response.questionnaire)}}</td>
              <td>{{ response.authored.split('.')[0] }}</td>
              <td>
                <ng-container *ngFor="let item of response.item ">
                  <ion-chip *ngIf="item.answer ">
                    {{ getTrueAnswer(item) }}
                  </ion-chip>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </table>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
