import {
  QuestionnaireResponse,
  QuestionnaireResponseItemAnswer,
} from 'fhir/r4';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { Observable } from 'rxjs';

/* eslint-disable @typescript-eslint/naming-convention */
import { CORE_HESTIA_CONFIG } from '@hestia/ngx-common';
import {
  HestiaFhirResourcesService,
  HestiaFhirUsersService,
} from '@hestia/ngx-fhir';
import { IHestiaCoreConfig, IHestiaUserProfile } from '@hestia/ngx-types';

import { EpenatQnrId } from '../../models/types';

@Component({
  standalone: true,
  selector: 'epenat-patient',
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule],
  templateUrl: 'patient.page.html',
  styles: [
    `
      table {
        width: 100%;
      }

      td,
      th {
        text-align: left;
        padding: 6px;
      }

      /* tr:nth-child(even) {
        background-color: #dddddd;
      } */
    `,
  ],
})
export class PatientPage implements OnInit {
  fhirUser$: Observable<IHestiaUserProfile>;
  fhirQuestionnaireResponses$: Observable<QuestionnaireResponse[]>;

  datePlaceholder = [new Date().toISOString().split('T', 1)[0]];
  extraDaysLimit = new Array(6);
  apiUrl: string;
  currentSegment: string;
  qnrId = EpenatQnrId;

  eventCreationForm = new FormGroup({
    date: new FormControl(this.datePlaceholder, [Validators.required]),
    extraDays: new FormControl(null, [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private fhirUsers: HestiaFhirUsersService,
    @Inject(CORE_HESTIA_CONFIG) private hestiaCoreConfig: IHestiaCoreConfig,
    private fhirResources: HestiaFhirResourcesService
  ) {
    this.apiUrl = this.hestiaCoreConfig.apiUrl;
    this.currentSegment =
      'https://zitelab.eu/fhir/questionnaire/' +
      this.qnrId.EPENAT_NAUSEA_WORDS_4_TO_18;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.fhirUser$ = this.fhirUsers.fetchUser(`Patient/${params.fhirId}`);
      this.fhirQuestionnaireResponses$ =
        this.fhirResources.fetchResponsesFromPatient({
          patientFhirId: params.fhirId,
          resolveReferences: true,
        });
    });
  }

  fullQnrId(qnrid) {
    console.log(qnrid);
    // debugger;
    // this.currentSegment = 'https://zitelab.eu/fhir/questionnaire/' + qnrid;
    return 'https://zitelab.eu/fhir/questionnaire/' + qnrid;
  }

  ageGroupLabel(ageGroup) {
    if (ageGroup === 'FOUR_TO_EIGHT') {
      return '4 to 8';
    }
    if (ageGroup === 'NINE_TO_EIGHTEEN') {
      return '9 to 18';
    }
  }

  getQuestionnaireName(resourceId) {
    switch (resourceId) {
      case 'https://zitelab.eu/fhir/questionnaire/' +
        this.qnrId.EPENAT_NAUSEA_WORDS_4_TO_18:
        return 'Definitions';
      case 'https://zitelab.eu/fhir/questionnaire/' + this.qnrId.EPENAT_4_TO_8:
        return 'Nausea (4Y - 8Y)';
      case 'https://zitelab.eu/fhir/questionnaire/' +
        this.qnrId.EPENAT_VOMIT_TIME:
        return 'Vomiting';
      case 'https://zitelab.eu/fhir/questionnaire/' +
        this.qnrId.EPENAT_MEDICINE:
        return 'Medicine';
      case 'https://zitelab.eu/fhir/questionnaire/' + this.qnrId.EPENAT_9_TO_18:
        return 'Nausea (9Y - 18Y)';
      default:
        break;
    }
  }

  getTrueAnswer(item: fhir4.QuestionnaireResponseItem) {
    const itemAnswer = item.answer as QuestionnaireResponseItemAnswer[];

    // console.log(itemAnswer);

    if (itemAnswer) {
      if (itemAnswer[0].valueCoding) {
        // console.log(itemAnswer[0].valueCoding);
        return itemAnswer[0].valueCoding.display;
      } else if (itemAnswer[0].valueString) {
        return itemAnswer[0].valueString || null;
      } else if (itemAnswer[0].valueDateTime) {
        return new Date(itemAnswer[0].valueDateTime).toLocaleString();
      }
    }

    return undefined;
  }
}
