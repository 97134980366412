import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgxFhirQnrFormsRoutingModule } from '@hestia/ngx-fhir-qnr-forms';
import { AuthGuard, AuthNoneGuard } from '@hestia/ngx-user';

import { EpenatRoutes } from './models/types';
import { AdminPage, LandingPage, PatientPage } from './pages';

const routes: Routes = [
  {
    path: '',
    title: 'Landing',
    canActivate: [AuthNoneGuard],
    component: LandingPage,
  },
  {
    path: EpenatRoutes.TabsPage,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tabs.page/tabs.page.module').then((m) => m.TabsPageModule),
  },
  {
    path: EpenatRoutes.OnboardingPage,
    loadChildren: () => import('./pages/onboarding.page/onboarding.page.module').then((m) => m.OnboardingPageModule),
  },
  {
    path: EpenatRoutes.PrivacyPage,
    title: 'Privacy Policy',
    loadChildren: () => import('./pages/privacy.page/privacy.page.module').then((m) => m.PrivacyPageModule),
  },
  {
    path: EpenatRoutes.QuestionnairePage,
    title: 'Questionnaire',
    canActivate: [AuthGuard],
    loadChildren: () => NgxFhirQnrFormsRoutingModule,
  },
  {
    path: EpenatRoutes.AdminPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        title: 'Dashboard',
        component: AdminPage,
      },
      {
        path: ':userType/:fhirId',
        title: 'Patient',
        component: PatientPage,
      },
    ],
  },
  {
    path: '',
    redirectTo: '/tabs',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
