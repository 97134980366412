import { Component, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';

import { HestiaUserFacade } from '@hestia/ngx-user';
import { PrivacyPage } from '../pages/privacy.page/privacy.page';
import { PrivacyPageModule } from '../pages/privacy.page/privacy.page.module';
import { environment } from '../../environments/environment';

@Component({
  standalone: true,
  selector: 'epenat-login-form',
  imports: [FormsModule, ReactiveFormsModule, IonicModule],
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar color="light">
        <ion-title>Credentials</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" (click)="dismissModal()">
            <ion-icon slot="icon-only" name="chevron-down-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <form [formGroup]="credentialsForm">
        <ion-list lines="full">
          <ion-item>
            <ion-icon slot="start" name="person-outline"></ion-icon>
            <ion-input
              formControlName="login"
              type="text"
              placeholder="Username"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-icon slot="start" name="lock-open-outline"></ion-icon>
            <ion-input
              formControlName="password"
              [type]="showPassword ? 'password' : 'text'"
              placeholder="Password"
            ></ion-input>
            <ion-button
              slot="end"
              [strong]="true"
              fill="clear"
              (click)="toggleShowPassword()"
            >
              {{ showPassword ? 'SHOW' : 'HIDE' }}
            </ion-button>
          </ion-item>
        </ion-list>

        <ion-list lines="full">
          <ion-list-header>
            <ion-label>Privacy Policy</ion-label>
          </ion-list-header>

          <ion-item>
            <ion-checkbox
              formControlName="privacyPolicy"
              slot="start"
            ></ion-checkbox>
            <ion-label>I agree to the Privacy Policy</ion-label>

            <ion-nav-link router-direction="forward" [component]="component">
              <ion-button slot="end" [strong]="true" fill="clear">
                READ
              </ion-button>
            </ion-nav-link>
          </ion-item>
        </ion-list>
      </form>
    </ion-content>

    <ion-footer class="ion-no-border">
      <ion-toolbar color="light">
        <ion-grid [fixed]="true">
          <ion-row>
            <ion-col>
              <ion-button
                expand="block"
                shape="round"
                strong="true"
                [disabled]="!credentialsForm.valid"
                (click)="submitCredentials()"
              >
                Sign In
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
  `,
  styles: [
    `
      ion-checkbox {
        --size: 24px;
        --background-checked: var(--ion-color-primary);
      }

      ion-checkbox::part(container) {
        border-radius: 6px;
        border: 2px solid var(--ion-color-primary);
      }
    `,
  ],
})
export class LoginFormComponent {
  showPassword = true;
  public component = PrivacyPage;

  credentialsForm = new FormGroup({
    login: new FormControl(environment?.testUsername, [
      Validators.minLength(3),
      Validators.required,
    ]),
    password: new FormControl(environment?.testPassword, [
      Validators.minLength(5),
      Validators.required,
    ]),
    privacyPolicy: new FormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private userFacade: HestiaUserFacade,
    private modalController: ModalController
  ) {}

  toggleShowPassword = () => (this.showPassword = !this.showPassword);

  dismissModal() {
    this.modalController.dismiss(null, 'cancel');
  }

  submitCredentials() {
    this.modalController.dismiss(
      {
        login: this.credentialsForm.value.login,
        password: this.credentialsForm.value.password,
      },
      'confirm'
    );
  }
}
