import { createAction, props } from '@ngrx/store';

import { IEpenatSpecificState } from './epenat-specific.reducer';

export const initPrimaryFhirLoad = createAction('[ePeNAT] Init Primary FHIR Load');

export const primaryFhirLoadSuccess = createAction('[ePeNAT] Primary FHIR Load Success');

export const primaryFhirLoadFailure = createAction('[ePeNAT] Primary FHIR Load Failure');

export const update = createAction('[ePeNAT] Update', props<{ update: Partial<IEpenatSpecificState> }>());

export const showAlertOnNoDefinitionSuccess = createAction('[ePeNAT] Show alert on no definition');

export const resetState = createAction('[ePeNAT] resetState');

export const handleLoginRedirect = createAction('[ePeNAT] Login redirect');

export const epenatSpecificActions = {
  initPrimaryFhirLoad,
  primaryFhirLoadSuccess,
  primaryFhirLoadFailure,
  resetState,
  update,
  showAlertOnNoDefinitionSuccess,
  handleLoginRedirect,
};
