/* eslint-disable @typescript-eslint/naming-convention */
export enum EpenatRoutes {
  HomePage = 'home',
  MedicinePage = 'medicine',
  DefinitionPage = 'definitions',
  SettingsPage = 'settings',
  TabsPage = 'tabs',
  LoginPage = 'login',
  PrivacyPage = 'privacy-policy',
  OnboardingPage = 'onboarding',
  QuestionnairePage = 'qnr',
  AdminPage = 'admin',
}

export enum ChemoBlockDayNumber {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
}

export enum ChemoBlockNumber {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
}

export enum DrugName {
  Aprepitant = 'Aprepitant',
  Dexamethasone = 'Dexamethasone',
  Diphenhydramine = 'Diphenhydramine',
  Dronabinol = 'Dronabinol',
  Fosaprepitant = 'Fosaprepitant',
  Granisetron = 'Granisetron',
  Lorazepam = 'Lorazepam',
  Methotrimeprazine = 'Methotrimeprazine',
  Metoclopramide = 'Metoclopramide',
  Nabilone = 'Nabilone',
  Olanzapine = 'Olanzapine',
  Ondansetron = 'Ondansetron',
  Palonosetron = 'Palonosetron',
  Scopolamine = 'Scopolamine',
  Other = 'Other',
}

export const drugNameColors: { [key in DrugName]: string } = {
  [DrugName.Aprepitant]: '#ffcccb',
  [DrugName.Dexamethasone]: '#ffc1e3',
  [DrugName.Diphenhydramine]: '#ffc4ff',
  [DrugName.Dronabinol]: '#d1d9ff',
  [DrugName.Fosaprepitant]: '#b6ffff',
  [DrugName.Granisetron]: '#b2fef7',
  [DrugName.Lorazepam]: '#d7ffd9',
  [DrugName.Methotrimeprazine]: '#ffffce',
  [DrugName.Metoclopramide]: '#ffffcf',
  [DrugName.Nabilone]: '#ffffb3',
  [DrugName.Olanzapine]: '#ffffb0',
  [DrugName.Ondansetron]: '#ffddc1',
  [DrugName.Palonosetron]: '#efdcd5',
  [DrugName.Scopolamine]: '#efd229',
  [DrugName.Other]: '#ffffff',
};

export const drugTradeNames: { [key in DrugName]: string } = {
  [DrugName.Aprepitant]: 'Emend',
  [DrugName.Dexamethasone]: 'Decadron',
  [DrugName.Diphenhydramine]: 'Benadryl',
  [DrugName.Dronabinol]: 'Marinol',
  [DrugName.Fosaprepitant]: 'Emend IV',
  [DrugName.Granisetron]: 'Kytril',
  [DrugName.Lorazepam]: 'Ativan',
  [DrugName.Methotrimeprazine]: 'Nozinan',
  [DrugName.Metoclopramide]: 'Maxeran/Reglan',
  [DrugName.Nabilone]: 'Cesamet',
  [DrugName.Olanzapine]: 'Zyprexa',
  [DrugName.Ondansetron]: 'Zofran',
  [DrugName.Palonosetron]: 'Aloxi',
  [DrugName.Scopolamine]: 'Hyoscine',
  [DrugName.Other]: 'Please specify',
};

export enum EpenatAgeGroup {
  FOUR_TO_EIGHT = 'FOUR_TO_EIGHT',
  NINE_TO_EIGHTEEN = 'NINE_TO_EIGHTEEN',
}

export enum EpenatQnrId {
  EPENAT_4_TO_8 = '11a84f69-49a9-4c9d-9615-f714ed2a013e',
  EPENAT_9_TO_18 = '32f76133-45d6-4f1c-9b93-1ffea8a08897',
  EPENAT_NAUSEA_WORDS_4_TO_18 = 'c2adfae3-a49d-4caf-96e5-b640675c649b',
  EPENAT_MEDICINE = 'd5213ed0-5cda-48e6-be64-c68143291385',
  EPENAT_VOMIT_TIME = 'cd088326-d986-4dc9-be3b-0eba98809d02',
}
