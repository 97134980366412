import '@angular/forms';

import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'epenat-nav',
  imports: [IonicModule],
  template: `
    <ion-nav [root]="root"></ion-nav>
  `,
  styles: [],
})
export class NavComponent {
  root: unknown;

  constructor() {}
}
