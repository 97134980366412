/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';

import { NavController } from '@ionic/angular';

import { EMPTY, concat, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { HestiaFhirResourcesService, HestiaFhirUsersService } from '@hestia/ngx-fhir';
import { qnrFormSessionActions, selectQnrFormSessionById } from '@hestia/ngx-fhir-qnr-forms';
import { selectUserState, userActions } from '@hestia/ngx-user';

import { EpenatQnrId, EpenatRoutes } from '../../models/types';
import { EpenatAppService } from '../../services/app.service';
import { epenatSpecificActions } from './epenat-specific.actions';

@Injectable()
export class EpenatSpecificEffects {
  constructor(
    private actions$: Actions,
    private navCtrl: NavController,
    private store: Store,
    private appService: EpenatAppService,
    private fhirUsers: HestiaFhirUsersService,
    private fhirResources: HestiaFhirResourcesService
  ) {}

  loadUserProfileSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.userProfileLoadSuccess),
      map((_action) => epenatSpecificActions.initPrimaryFhirLoad())
    );
  });

  primaryFhirLoadSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(epenatSpecificActions.primaryFhirLoadSuccess),
      map((_action) => epenatSpecificActions.handleLoginRedirect())
    );
  });

  logoutUserSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userActions.logoutSuccess),
        map((_action) => this.appService.handleLogout(true))
      );
    },
    { dispatch: false }
  );

  showAlertOnNoDefinition$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(epenatSpecificActions.showAlertOnNoDefinitionSuccess),
        map((_action) => this.appService.presentAlert())
      );
    },
    { dispatch: false }
  );

  logoutUserFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userActions.logoutFailure),
        map((_action) => this.appService.handleLogout(true))
      );
    },
    { dispatch: false }
  );

  initPrimaryFhirLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(epenatSpecificActions.initPrimaryFhirLoad),
      mergeMap((_action) =>
        this.fhirResources
          .fetchResponsesFromPatient({
            patientFhirId: '',
            resolveReferences: true,
            _sort: '-_lastUpdated',
          })
          .pipe(
            map((_result) => epenatSpecificActions.primaryFhirLoadSuccess()),
            catchError((error) => {
              return of(epenatSpecificActions.primaryFhirLoadFailure());
            })
          )
      )
    );
  });

  handleLoginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(epenatSpecificActions.handleLoginRedirect),
        map((_action) => this.appService.handleLoginRedirect())
      );
    },
    { dispatch: false }
  );

  qnrSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.completeSession),
      switchMap((action) =>
        this.store.select(selectQnrFormSessionById(action.id)).pipe(
          // eslint-disable-next-line ngrx/avoid-mapping-selectors
          map((session) => {
            return { action, session };
          })
        )
      ),
      mergeMap(({ action, session }) => {
        if (!session) {
          return;
        }
        if (
          session.questionnaireId === EpenatQnrId.EPENAT_4_TO_8 ||
          session.questionnaireId === EpenatQnrId.EPENAT_9_TO_18 ||
          session.questionnaireId === EpenatQnrId.EPENAT_VOMIT_TIME
        ) {
          this.navCtrl
            .navigateBack(['/', EpenatRoutes.TabsPage, EpenatRoutes.HomePage])
            .then(() => qnrFormSessionActions.clearSessions());

          return;
        }
        if (session.questionnaireId === EpenatQnrId.EPENAT_MEDICINE) {
          this.navCtrl
            .navigateBack(['/', EpenatRoutes.TabsPage, EpenatRoutes.MedicinePage])
            .then(() => qnrFormSessionActions.clearSessions());

          return;
        }
        if (!(session.questionnaireId === EpenatQnrId.EPENAT_NAUSEA_WORDS_4_TO_18)) {
          this.navCtrl.navigateRoot(['/', EpenatRoutes.TabsPage, EpenatRoutes.DefinitionPage]);

          return;
        }
        let wordsForNausea: string;
        const wordsForVomiting = session.values?.['wordsForVomiting'] as string;
        const wordsForNauseaSources = [
          'wordsForNauseaHasVomited',
          'wordsForNauseaOnlyAlmostVomited',
          'wordsForNauseaNoExperience',
        ];
        for (const source of wordsForNauseaSources) {
          if (session.values?.[source]) {
            wordsForNausea = session.values[source] as string;
            break;
          }
        }
        this.navCtrl.navigateRoot(['/', EpenatRoutes.TabsPage, EpenatRoutes.DefinitionPage]);
        return concat(
          of(
            epenatSpecificActions.update({
              update: {
                definitionsTimestamp: new Date().toISOString(),
                wordsForNausea,
                wordsForVomiting,
              },
            })
          ),
          of(qnrFormSessionActions.clearSessions())
        );
      })
    );
  });
}
