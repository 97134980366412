import { Injectable, NgZone } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { HestiaUserFacade } from '../user.facade';

@Injectable()
export class HestiaInactivityInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private navCtrl: NavController,
    private zone: NgZone,
    private store: Store,
    private userFacade: HestiaUserFacade
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        
        if (error.status === 401) {
          console.log(error);
          if(error?.error?.message == 'INACTIVITY_TIMEOUT_UNAUTHORIZED'){
            // console.log(error);
            window.location.href = '/logout-confirm';
          }
            
            // this.userFacade.initLogout();
            // window.location.href = '/logout-confirm';
          // Redirect to the inactive page
          // this.router.navigate(['/login']).then(() => {
          //  console.log('Navigation to inactive_page successful');
          // }).catch(err => {
          //  console.error('Navigation error:', err);
          //});
        }
        return throwError(() => error);
      })
    );
  }
}
