import { formatISO } from 'date-fns';
import { DeepPartial } from 'utility-types';

import { WidgetConfig } from '../../../models/widget.config';

export class DateTimeWidgetConfig extends WidgetConfig<string> {
  widgetType = 'DateTimeWidget';
  datetimeWidgetFormat: 'date' | 'time' | 'date-time' = 'date-time';

  constructor(config: DeepPartial<WidgetConfig<unknown>>) {
    super(config as unknown);
    this.datetimeWidgetFormat = config?.datetimeWidgetFormat ?? 'date-time';

    this.value = formatISO(new Date());
    this.defaultValue = formatISO(new Date());
  }
}
