import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'epenat-privacy-page',
  templateUrl: 'privacy.page.html',
  styleUrls: ['privacy.page.scss'],
})
export class PrivacyPage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
