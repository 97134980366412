import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import { ChemoBlockDayNumber, ChemoBlockNumber } from '../../models/types';
import * as QnrAnswerActions from './qnr-answer.actions';
import { EpenatQnrAnswer } from './qnr-answer.model';

export const epenatQnrAnswersFeatureKey = 'ePenatQnrAnswers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EpenatQnrAnswersState extends EntityState<EpenatQnrAnswer> {
  // additional entities state properties
}

export const adapter: EntityAdapter<EpenatQnrAnswer> =
  createEntityAdapter<EpenatQnrAnswer>();

export const initialState: EpenatQnrAnswersState = adapter.getInitialState({
  // additional entity state properties
});

export const epenatQnrAnswersReducer = createReducer(
  initialState,
  on(QnrAnswerActions.addQnrAnswer, (state, action) =>
    adapter.addOne(action.qnrAnswer, state)
  ),
  on(QnrAnswerActions.upsertQnrAnswer, (state, action) =>
    adapter.upsertOne(action.qnrAnswer, state)
  ),
  on(QnrAnswerActions.addQnrAnswers, (state, action) =>
    adapter.addMany(action.qnrAnswers, state)
  ),
  on(QnrAnswerActions.upsertQnrAnswers, (state, action) =>
    adapter.upsertMany(action.qnrAnswers, state)
  ),
  on(QnrAnswerActions.updateQnrAnswer, (state, action) =>
    adapter.updateOne(action.qnrAnswer, state)
  ),
  on(QnrAnswerActions.updateQnrAnswers, (state, action) =>
    adapter.updateMany(action.qnrAnswers, state)
  ),
  on(QnrAnswerActions.deleteQnrAnswer, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(QnrAnswerActions.deleteQnrAnswers, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(QnrAnswerActions.loadQnrAnswers, (state, action) =>
    adapter.setAll(action.qnrAnswers, state)
  ),
  on(QnrAnswerActions.clearQnrAnswers, (state) => adapter.removeAll(state))
);

export const selectEpenatQnrAnswersState =
  createFeatureSelector<EpenatQnrAnswersState>(epenatQnrAnswersFeatureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectEpenatQnrAnswersState);

export const selectEpenatQnrAnswersByChemoBlockDay = (
  chemoBlock: ChemoBlockNumber,
  day: ChemoBlockDayNumber
) =>
  createSelector(selectAll, (qnranswers) =>
    qnranswers.filter(
      (elem) =>
        elem.chemoBlockNumber === chemoBlock && elem.chemoBlockDayNumber === day
    )
  );
