import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { EpenatQnrAnswer } from './qnr-answer.model';

export const loadQnrAnswers = createAction(
  '[QnrAnswer/API] Load QnrAnswers',
  props<{ qnrAnswers: EpenatQnrAnswer[] }>()
);

export const addQnrAnswer = createAction(
  '[QnrAnswer/API] Add QnrAnswer',
  props<{ qnrAnswer: EpenatQnrAnswer }>()
);

export const upsertQnrAnswer = createAction(
  '[QnrAnswer/API] Upsert QnrAnswer',
  props<{ qnrAnswer: EpenatQnrAnswer }>()
);

export const addQnrAnswers = createAction(
  '[QnrAnswer/API] Add QnrAnswers',
  props<{ qnrAnswers: EpenatQnrAnswer[] }>()
);

export const upsertQnrAnswers = createAction(
  '[QnrAnswer/API] Upsert QnrAnswers',
  props<{ qnrAnswers: EpenatQnrAnswer[] }>()
);

export const updateQnrAnswer = createAction(
  '[QnrAnswer/API] Update QnrAnswer',
  props<{ qnrAnswer: Update<EpenatQnrAnswer> }>()
);

export const updateQnrAnswers = createAction(
  '[QnrAnswer/API] Update QnrAnswers',
  props<{ qnrAnswers: Update<EpenatQnrAnswer>[] }>()
);

export const deleteQnrAnswer = createAction(
  '[QnrAnswer/API] Delete QnrAnswer',
  props<{ id: string }>()
);

export const deleteQnrAnswers = createAction(
  '[QnrAnswer/API] Delete QnrAnswers',
  props<{ ids: string[] }>()
);

export const clearQnrAnswers = createAction('[QnrAnswer/API] Clear QnrAnswers');
