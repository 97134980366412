import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { VomitEvent } from './vomit-event.model';

export const loadVomitEvents = createAction(
  '[VomitEvent/API] Load VomitEvents', 
  props<{ vomitEvents: VomitEvent[] }>()
);

export const addVomitEvent = createAction(
  '[VomitEvent/API] Add VomitEvent',
  props<{ vomitEvent: VomitEvent }>()
);

export const upsertVomitEvent = createAction(
  '[VomitEvent/API] Upsert VomitEvent',
  props<{ vomitEvent: VomitEvent }>()
);

export const addVomitEvents = createAction(
  '[VomitEvent/API] Add VomitEvents',
  props<{ vomitEvents: VomitEvent[] }>()
);

export const upsertVomitEvents = createAction(
  '[VomitEvent/API] Upsert VomitEvents',
  props<{ vomitEvents: VomitEvent[] }>()
);

export const updateVomitEvent = createAction(
  '[VomitEvent/API] Update VomitEvent',
  props<{ vomitEvent: Update<VomitEvent> }>()
);

export const updateVomitEvents = createAction(
  '[VomitEvent/API] Update VomitEvents',
  props<{ vomitEvents: Update<VomitEvent>[] }>()
);

export const deleteVomitEvent = createAction(
  '[VomitEvent/API] Delete VomitEvent',
  props<{ id: string }>()
);

export const deleteVomitEvents = createAction(
  '[VomitEvent/API] Delete VomitEvents',
  props<{ ids: string[] }>()
);

export const clearVomitEvents = createAction(
  '[VomitEvent/API] Clear VomitEvents'
);
