import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as chemoBlockDayActions from './chemo-block-day.actions';
import { userActions } from '@hestia/ngx-user';

@Injectable()
export class ChemoBlockDayEffects {
  constructor(private actions$: Actions) {}

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.logout),
      mergeMap(() => of(chemoBlockDayActions.clearChemoBlockDays()))
    );
  });
}
