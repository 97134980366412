import { AgeGroup, HestiaUserType, IHestiaUserProfile } from '@hestia/ngx-types';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HestiaFhirResourcesService, HestiaFhirUsersService } from '@hestia/ngx-fhir';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IonicModule, ModalController, NavController } from '@ionic/angular';

/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { EpenatAppService } from '../../services/app.service';
import { Observable } from 'rxjs';
import { PatientFormComponent } from '../../components/patient-form';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'epenat-definition-page',
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule, RouterModule],
  templateUrl: 'admin.page.html',
  styles: [
    `
      ion-searchbar {
        padding: 6px;
      }

      ion-modal {
        --max-width: 400px;
      }

      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        border-style: hidden;
        width: 100%;
      }

      th {
        font-weight: 600;
      }

      td,
      th {
        text-align: left;
        padding: 16px;
      }

      tr:not(:first-child):hover {
        cursor: pointer;
        opacity: 0.75;
      }

      tr:nth-child(even) {
        background-color: rgba(var(--ion-color-primary-rgb), 0.15);
      }
    `,
  ],
})
export class AdminPage implements OnInit {
  patients$: Observable<IHestiaUserProfile[]>;
  apiUrl: string;

  constructor(
    public core: EpenatAppService,
    private navCtrl: NavController,
    private fhirUsers: HestiaFhirUsersService,
    private fhirResources: HestiaFhirResourcesService,
    private modalController: ModalController,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.patients$ = this.fhirUsers.fetchUsers({
      userType: HestiaUserType.Patient,
    });
    this.apiUrl = environment.apiUrl;
  }

  downloadData() {
    this.http.get(`${this.apiUrl}@download-data`, { responseType: 'json' }).subscribe({
      next: (response: any) => {
        try {
          const base64Data = response?.data; // Assuming response.data contains the base64-encoded xlsx data
          const filename = response?.filename;

          const binaryData = atob(base64Data);
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uint8Array = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // MIME type for XLSX
          });

          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename; // Set the desired file name
          a.click();
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error while handling the response:', error);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getAgeGroup(ageGroup) {
    switch (ageGroup) {
      case AgeGroup.FOUR_TO_EIGHT:
        return '4 to less than 8 years';
      case AgeGroup.NINE_TO_EIGHTEEN:
        return '8 to less than 18 years';
      default:
        return null;
    }
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: PatientFormComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.patients$ = this.fhirUsers.fetchUsers({
        userType: HestiaUserType.Patient,
      });
    }
  }
}
