<ion-split-pane contentId="main" when="xl">
  <!--  the side menu  -->
  <ion-menu
    contentId="main"
    side="end"
    type="overlay"
    *ngIf="userState$ | async as user"
    [disabled]="!user.jwt"
  >
    <ion-header class="ion-no-border">
      <ion-toolbar color="light">
        <ion-title>PeNAT</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list lines="none" *ngIf="user.userType === 'Patient'">
        <ion-menu-toggle
          auto-hide="false"
          *ngFor="let p of appPages; let i = index"
        >
          <ion-item
            routerDirection="root"
            [routerLink]="[p.url]"
            lines="none"
            detail="false"
            routerLinkActive="selected"
          >
            <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-list lines="none" *ngIf="user.userType === 'Practitioner'">
        <ion-menu-toggle [autoHide]="false">
          <ion-item
            routerDirection="root"
            [routerLink]="['/admin']"
            detail="false"
            routerLinkActive="selected"
          >
            <ion-icon slot="start" name="grid-outline"></ion-icon>
            <ion-label>
              <h2>Dashboard</h2>
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-list lines="none" *ngIf="user.userId">
        <ion-list-header>
          <ion-label>{{ user.userId.toUpperCase() }}</ion-label>
        </ion-list-header>

        <ion-item *ngIf="user.userType === 'Patient'">
          <ion-label position="stacked">Age Group</ion-label>
          <ion-select
            [value]="user.ageGroup"
            (ionChange)="handleAgeGroupChange($event)"
            placeholder="Select"
          >
            <ion-select-option [value]="penatAgeGroup.FOUR_TO_EIGHT">
              4 to less than 8 years
            </ion-select-option>
            <ion-select-option [value]="penatAgeGroup.NINE_TO_EIGHTEEN">
              8 to less than 18 years
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item [button]="true" detail="false" (click)="presentLogoutAlert()">
          <ion-icon slot="start" name="exit-outline"></ion-icon>
          <ion-label>Sign out</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <!-- the main content -->
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-split-pane>
