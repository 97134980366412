/* eslint-disable @typescript-eslint/naming-convention */
/// <reference types="fhir" />
import { Action, createReducer, on } from '@ngrx/store';

import { EpenatAgeGroup } from '../../models/types';
import { epenatSpecificActions } from './epenat-specific.actions';

export const epenatSpecificFeatureKey = 'epenatApp';

export interface IEpenatSpecificState {
  targetDate: string | null;
  ageGroup: EpenatAgeGroup;
  wordsForNausea: string | null;
  wordsForVomiting: string | null;
  confirmedVomitDates: [];
  confirmedMedicineDates: [];
  startDate: string | null;
  endDate: string | null;
  definitionsTimestamp: string | null;
}

export const initialState: IEpenatSpecificState = {
  targetDate: new Date().toISOString().split('T')[0],
  ageGroup: EpenatAgeGroup.FOUR_TO_EIGHT,
  wordsForNausea: null,
  wordsForVomiting: null,
  confirmedVomitDates: [],
  confirmedMedicineDates: [],
  startDate: null,
  endDate: null,
  definitionsTimestamp: null,
};

export const epenatSpecificReducer = createReducer(
  initialState,
  on(
    epenatSpecificActions.initPrimaryFhirLoad,
    (state, _action): IEpenatSpecificState => ({
      ...state,
    })
  ),
  on(
    epenatSpecificActions.primaryFhirLoadSuccess,
    (state, _action): IEpenatSpecificState => ({
      ...state,
    })
  ),
  on(
    epenatSpecificActions.primaryFhirLoadFailure,
    (state, _action): IEpenatSpecificState => ({
      ...state,
    })
  ),
  on(epenatSpecificActions.update, (state, action): IEpenatSpecificState => ({ ...state, ...action.update })),
  on(epenatSpecificActions.resetState, (_state, _action): IEpenatSpecificState => initialState)
);

export function reducer(state: IEpenatSpecificState | undefined, action: Action) {
  return epenatSpecificReducer(state, action);
}
