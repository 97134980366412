import {
  ChemoBlockDaysState,
  chemoBlockDaysFeatureKey,
  chemoBlockDaysReducer,
} from './+chemo-block-day/chemo-block-day.reducer';
import {
  EpenatQnrAnswersState,
  epenatQnrAnswersReducer,
} from './+qnr-answer/qnr-answer.reducer';
import {
  IEpenatSpecificState,
  epenatSpecificFeatureKey,
  epenatSpecificReducer,
} from './+state/epenat-specific.reducer';
import { IFhirResourcesState, fhirResourcesFeatureKey } from '@hestia/ngx-fhir';
import { IHestiaUserState, userFeatureKey } from '@hestia/ngx-user';
import {
  IQnrFormSessionsState,
  IUserControlledSettingsState,
  qnrFormSessionFeatureKey,
  qnrModuleSettingsFeatureKey,
} from '@hestia/ngx-fhir-qnr-forms';
import {
  MedicineUsesState,
  medicineUsesFeatureKey,
  medicineUsesReducer,
} from './+medicine-use/medicine-use.reducer';
import {
  VomitEventsState,
  vomitEventsFeatureKey,
  vomitEventsReducer,
} from './+vomit-event/vomit-event.reducer';

import { ActionReducerMap } from '@ngrx/store';
import { epenatQnrAnswersFeatureKey } from './+qnr-answer/qnr-answer.reducer';

export const reducers: ActionReducerMap<unknown> = {
  [epenatSpecificFeatureKey]: epenatSpecificReducer,
  [chemoBlockDaysFeatureKey]: chemoBlockDaysReducer,
  [medicineUsesFeatureKey]: medicineUsesReducer,
  [epenatQnrAnswersFeatureKey]: epenatQnrAnswersReducer,
  [vomitEventsFeatureKey]: vomitEventsReducer,
};

export interface IEpenatAppState {
  [epenatSpecificFeatureKey]: IEpenatSpecificState;
  [userFeatureKey]: IHestiaUserState;
  [fhirResourcesFeatureKey]: IFhirResourcesState;
  [qnrFormSessionFeatureKey]: IQnrFormSessionsState;
  [qnrModuleSettingsFeatureKey]: IUserControlledSettingsState;
  [chemoBlockDaysFeatureKey]: ChemoBlockDaysState;
  [medicineUsesFeatureKey]: MedicineUsesState;
  [epenatQnrAnswersFeatureKey]: EpenatQnrAnswersState;
  [vomitEventsFeatureKey]: VomitEventsState;
}
