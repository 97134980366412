<ng-container *ngIf="userState$ | async as patient">
  <ion-header collapse="fade" class="ion-no-border">
    <ion-toolbar color="light">
      <!-- <ion-buttons slot="start">
        <ion-button
          color="primary"
          (click)="dateNavigation(false)"
          [disabled]="patient.startDate.includes(targetDate$ | async)"
        >
          <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        </ion-button>
        <ion-button
          color="primary"
          (click)="dateNavigation(true)"
          [disabled]="(targetDate$ | async) === todaysDate"
        >
          <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </ion-buttons> -->

      <ion-buttons slot="start">
        <ion-datetime-button datetime="datetime"></ion-datetime-button>

        <ion-popover [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime
              id="datetime"
              presentation="date"
              [value]="targetDate$ | async"
              (ionChange)="dateChange($event)"
              [min]="patient.startDate || todaysDate"
              [max]="todaysDate"
            ></ion-datetime>
          </ng-template>
        </ion-popover>
      </ion-buttons>

      <ion-buttons slot="end">
        <ion-button color="primary" (click)="goToday()" [disabled]="(targetDate$ | async) === todaysDate">
          Today
        </ion-button>

        <ion-menu-button color="primary"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col [size]="12">
          <ion-card>
            <ion-item id="cardHeader" lines="none">
              <ion-label>
                <p>Tell us about your medicine</p>
                <h2>{{ getIsToday(targetDate$ | async) ? 'I have taken ' : 'I took'}} ..</h2>
              </ion-label>
            </ion-item>

            <ion-grid>
              <ion-row>
                <ion-col [sizeMd]="12" [size]="12">
                  <ion-button color="primary" expand="block" shape="round" (click)="startMedicineQuestionnaire()">
                    <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                    Add medicine
                  </ion-button>
                </ion-col>
                <!-- <ion-col [sizeMd]="4" [size]="12">
                  <ion-button
                    shape="round"
                    expand="block"
                    fill="outline"
                    [color]="patient?.confirmedMedicineDates?.includes(targetDate$ | async) ? 'success' : 'primary'"
                    (click)="confirmMedicineEntries()"
                  >
                    <ion-icon
                      slot="start"
                      [name]=" patient?.confirmedMedicineDates?.includes(targetDate$ | async) ? 'lock-closed' :'lock-open'"
                    ></ion-icon>
                    {{ patient?.confirmedMedicineDates?.includes(targetDate$ | async) ? 'Confirmed' : 'Confirm' }}
                  </ion-button>
                </ion-col> -->
              </ion-row>
              <ion-item lines="none">
                <ion-label>Entries complete for the day?</ion-label>
                <ion-toggle
                  slot="end"
                  [checked]="patient?.confirmedMedicineDates?.includes(targetDate$ | async)"
                  (ionChange)="confirmMedicineEntriesToggle()"
                />
              </ion-item>
              <ion-row *ngIf="medicineResponses$ | async as responses">
                <ng-container *ngFor="let response of responses | keyvalue;">
                  <ion-col [size]="12" [sizeMd]="6" *ngIf="response.key.split('|')[1].includes(targetDate$ | async)">
                    <ion-card>
                      <ion-card-header>
                        <ion-card-subtitle>{{response.key.split('|')[0]}}</ion-card-subtitle>
                      </ion-card-header>
                      <ion-card-content>
                        <ion-row>
                          <ng-container *ngFor="let medicine of convertToList(response.value)">
                            <ion-col
                              *ngIf="medicine.item[2].answer[0].valueDateTime.includes(targetDate$ | async)"
                              [size]="6"
                              [sizeMd]="4"
                            >
                              <ion-button
                                shape="round"
                                size="small"
                                expand="block"
                                color="medium"
                                (click)="presentActionSheet(medicine)"
                              >
                                {{ toDate(medicine.item[2].answer[0].valueDateTime) | dfnsFormat: "p" }}
                              </ion-button>
                            </ion-col>
                          </ng-container>
                        </ion-row>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                </ng-container>
              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
