<ion-content [scrollY]="false" color="primary">
  <swiper
    (swiper)="setSwiperInstance($event)"
    [keyboard]="true"
    [pagination]="true"
  >
    <ng-template swiperSlide>
      <ion-text color="light">
        <img src="./assets/penat_logo_alone_white.png" />
        <h2>Welcome to the PeNAT app!</h2>
        <p>
          To get you started on the right foot, please swipe to read some
          important information about the app.
        </p>
      </ion-text>
    </ng-template>
    <ng-template swiperSlide>
      <ion-text color="light">
        <img class="ScreenShot" src="./assets/screenHome.png" />
        <h2>What is the PeNAT?</h2>
        <p>
          The Pediatric Nausea Assessment Tool (PeNAT) allows children and teens
          4 to 18 years old receiving cancer treatments to log information about
          their nausea and vomiting symptoms.
        </p>
        <p>
          Caregivers can enter information into the app for their child but only
          children and teens can decide if they are nauseated and how bad their
          nausea is.
        </p>
      </ion-text>
    </ng-template>
    <ng-template swiperSlide>
      <ion-text color="light">
        <img class="ScreenShot" src="./assets/screenDef.png" />
        <h2>What information is entered into the PeNAT app?</h2>
        <p>
          Children, teens or their caregivers enter the words they use for vomit
          and nausea when they talk together at home.
        </p>
        <p>Then, children and teens log:</p>
        <ul>
          <li>
            their nausea severity using a faces scale. (They do this at least 2
            times a day – once in the morning and once in the evening)
          </li>
          <li>the time that they vomit or retch</li>
          <li>the medicines they take to control nausea and vomiting.</li>
        </ul>
      </ion-text>
    </ng-template>
    <ng-template swiperSlide>
      <ion-text color="light">
        <img src="./assets/penat_logo_alone_white.png" />
        <h2>What else do I need to know about the PeNAT app?</h2>
        <p>
          Members of the study team will show you how to use the app. Please ask
          them for help using the app or contact the app developers.
        </p>
        <p>
          Members of your/your child’s healthcare team do not see the PeNAT
          scores automatically. You can show them the PeNAT scores if you wish.
        </p>
      </ion-text>
    </ng-template>
    <ng-template swiperSlide>
      <ion-text color="light">
        <img src="./assets/penat_logo_alone_white.png" />
        <h2>How will the information entered into the PeNAT app be used?</h2>
        <p>
          It will help healthcare professionals understand the symptoms that
          children and teens have when they receive cancer treatments.
        </p>
        <p>
          For example, it may help determine the usefulness of a new treatment
          for controlling nausea and vomiting.
        </p>
      </ion-text>
    </ng-template>
  </swiper>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar color="primary">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col>
          <ion-button
            id="open-modal"
            expand="block"
            shape="round"
            [strong]="true"
            (click)="openModal()"
            color="light"
          >
            Use the PeNAT
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
