/* eslint-disable @typescript-eslint/naming-convention */
import { format, formatISO, isToday } from 'date-fns';

/* eslint-disable ngrx/avoid-mapping-selectors */
import { QuestionnaireResponseItemAnswer } from 'fhir/r3';
import { QuestionnaireResponse } from 'fhir/r4';
import { DateFnsModule } from 'ngx-date-fns';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ActionSheetController, AlertController, IonicModule, NavController, ToastController } from '@ionic/angular';

import { Observable, firstValueFrom, lastValueFrom, map, mergeMap, of } from 'rxjs';

import { Store } from '@ngrx/store';

import { HestiaFhirResourcesService, HestiaFhirUsersService, selectQnrResponses } from '@hestia/ngx-fhir';
import { QuestionnaireResponseService } from '@hestia/ngx-fhir-qnr-forms';
import { selectUserState, userActions } from '@hestia/ngx-user';

import { EpenatQnrId, EpenatRoutes } from '../../models/types';
import { EpenatAppService } from '../../services/app.service';
import { update } from '../../store/+state/epenat-specific.actions';
import { selectEpenatSpecificState } from '../../store/+state/epenat-specific.selectors';

@Component({
  selector: 'epenat-medicine-page',
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule, RouterModule, DateFnsModule],
  templateUrl: 'medicine.page.html',
  styles: [
    `
      ion-datetime-button {
        padding-left: 5px;
      }
    `,
  ],
})
export class MedicinePage {
  userState$ = this.store.select(selectUserState);
  qnrResponses$ = this.store.select(selectQnrResponses);
  medicineResponses$: Observable<QuestionnaireResponse[]>;

  targetDate$ = this.store.select(selectEpenatSpecificState).pipe(map((e) => e.targetDate.split('T')[0]));

  todaysDate = new Date().toISOString().split('T')[0];

  constructor(
    private actionSheetCtrl: ActionSheetController,
    public core: EpenatAppService,
    public qnrResponse: QuestionnaireResponseService,
    private navCtrl: NavController,
    private store: Store,
    private fhirUsers: HestiaFhirUsersService,
    private fhirResources: HestiaFhirResourcesService,
    private alertController: AlertController,
    public qResponse: QuestionnaireResponseService,
    private toastController: ToastController
  ) {
    this.store.dispatch(update({ update: { targetDate: new Date().toISOString() } }));
    this.medicineResponses$ = this.getMedicineResponses();
  }

  toDate = (date) => new Date(date);
  getIsToday = (date) => isToday(new Date(date));

  async dateNavigation(navigateForward: boolean) {
    const currentDate = await firstValueFrom(this.targetDate$);
    const formatCurrentDate = new Date(currentDate);

    if (navigateForward) {
      formatCurrentDate.setDate(formatCurrentDate.getDate() + 1);
    } else {
      formatCurrentDate.setDate(formatCurrentDate.getDate() - 1);
    }

    const newDate = formatCurrentDate.toISOString().split('T')[0];

    this.store.dispatch(update({ update: { targetDate: newDate } }));
  }

  goToday() {
    this.store.dispatch(update({ update: { targetDate: new Date().toISOString().split('T')[0] } }));
  }

  dateChange(e) {
    this.store.dispatch(update({ update: { targetDate: e.detail.value } }));
  }

  getMedicineResponses(): Observable<any> {
    return this.qnrResponses$.pipe(
      mergeMap((val) => of(val)),
      map((studentList) =>
        studentList.filter(
          (e) => e.questionnaire === 'https://zitelab.eu/fhir/questionnaire/d5213ed0-5cda-48e6-be64-c68143291385'
        )
      ),
      map((result) => {
        const groups = result.reduce((groups, item) => {
          const dayKey = format(new Date(item.item[2].answer[0].valueDateTime), 'yyyy-MM-dd');

          const answer = item?.item[0]?.answer[0]?.valueCoding?.display.includes('Other')
            ? item?.item[1]?.answer[0]?.valueString
            : item?.item[0]?.answer[0]?.valueCoding?.display;

          (groups[answer + '|' + dayKey] = groups[answer + '|' + dayKey] || []).push(item);

          return groups;
        }, {});

        console.log(groups);

        return groups;
      })
    );
  }

  convertToList(responses) {
    const data = [];

    for (const row in responses) {
      if (responses.hasOwnProperty(row)) {
        data.push(responses[row]);
      }
    }
    return data;
  }

  async startMedicineQuestionnaire() {
    const currentTime = formatISO(new Date()).split('T')[1];
    const date = `${this.core.coreState.targetDate}T${currentTime}`;

    await this.core
      .initQuestionnaire({
        label: 'Medicine Questionnaire',
        resourceId: EpenatQnrId.EPENAT_MEDICINE,
        values: {
          timeOfUse: date,
        },
      })
      .then((sessionId) => {
        this.navCtrl.navigateForward(`/${EpenatRoutes.QuestionnairePage}/${sessionId}`);
      });
  }

  async confirmMedicineEntriesToggle() {
    const user = await firstValueFrom(this.userState$);

    const confirmedDates = [...user.confirmedMedicineDates];

    if (confirmedDates.includes(this.core.coreState.targetDate)) {
      confirmedDates.forEach((date, index) => {
        if (date === this.core.coreState.targetDate) {
          confirmedDates.splice(index, 1);
        }
      });
    } else {
      confirmedDates.push(this.core.coreState.targetDate);
    }

    try {
      this.fhirUsers
        .patchUser({
          confirmedMedicineDates: [...new Set(confirmedDates)],
        })
        .subscribe((e) => {
          console.log(e);
          return this.store.dispatch(
            userActions.updateUser({
              confirmedMedicineDates: [...new Set(confirmedDates)],
            })
          );
        });
    } catch (e) {
      const alert = await this.alertController.create({
        header: 'Could not complete action',
        message: 'Please try again',
      });

      await alert.present();
    }
  }

  // async confirmMedicineEntries() {
  //   const user = await firstValueFrom(this.userState$);

  //   const confirmedDates = [...user.confirmedMedicineDates];

  //   if (confirmedDates.includes(this.core.coreState.targetDate)) {
  //     confirmedDates.forEach((date, index) => {
  //       if (date === this.core.coreState.targetDate) {
  //         confirmedDates.splice(index, 1);
  //         console.log(date, this.core.coreState.targetDate);
  //       }
  //     });
  //   } else {
  //     confirmedDates.push(this.core.coreState.targetDate);
  //   }

  //   const alert = await this.alertController.create({
  //     header: confirmedDates.includes(this.core.coreState.targetDate)
  //       ? 'Confirm medicine entries'
  //       : 'Undo confirmation',
  //     message: confirmedDates.includes(this.core.coreState.targetDate)
  //       ? 'By confirming what you have entered you are telling us that the information entered into the app is complete. Note that you can undo your confirmation later if you need to make changes.'
  //       : 'Do you wish to undo your confirmation of the entries, so you can edit them?',
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //       },
  //       {
  //         text: confirmedDates.includes(this.core.coreState.targetDate) ? 'Confirm' : 'Undo',
  //         role: 'confirm',
  //         handler: () => {
  //           try {
  //             this.fhirUsers
  //               .patchUser({
  //                 confirmedMedicineDates: [...new Set(confirmedDates)],
  //               })
  //               .subscribe(() =>
  //                 this.store.dispatch(
  //                   userActions.updateUser({
  //                     confirmedMedicineDates: [...new Set(confirmedDates)],
  //                   })
  //                 )
  //               );
  //           } catch (e) {
  //             console.log(e);
  //           }
  //         },
  //       },
  //     ],
  //   });

  //   await alert.present();
  // }

  async presentActionSheet(response: fhir4.QuestionnaireResponse) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: format(new Date(response.item[2].answer[0].valueDateTime), 'p'),
      buttons: [
        {
          text: 'Edit',
          handler: async () =>
            await this.core
              .initQuestionnaire({
                label: 'Medicine Questionnaire',
                resourceId: EpenatQnrId.EPENAT_MEDICINE,
                sId: response.id,
                qnrResponse: response,
              })
              .then((sessionId) => this.navCtrl.navigateForward(`/${EpenatRoutes.QuestionnairePage}/${sessionId}`)),
        },
        {
          text: 'Delete',
          role: 'destructive',
          handler: async () =>
            this.core.deleteResponse(response.id).then((msg) => {
              const vomittime = format(new Date(response.item[2].answer[0].valueDateTime), 'p');
              this.presentToast('Successfuly deleted: ' + vomittime + '!');
            }),
        },
        {
          text: 'Dismiss',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });

    await actionSheet.present();
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
      position: 'top',
      color: 'success',
    });

    await toast.present();
  }
}
