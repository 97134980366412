<ion-header collapse="fade" class="ion-no-border">
  <ion-toolbar color="light">
    <ion-buttons slot="secondary">
      <ion-button color="primary" (click)="openModal()">
        <ion-icon slot="icon-only" name="person-add-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Dashboard</ion-title>
    <ion-buttons slot="primary">
      <ion-menu-button color="primary"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-button (click)="downloadData()">Download data</ion-button>
      <ion-col [size]="12" *ngIf="patients$ | async as patients">
        <ion-card>
          <table>
            <tr>
              <th>User</th>
              <th>Age Group</th>
              <th>Start</th>
              <th>End</th>
            </tr>
            <tr *ngFor="let patient of patients; let i = index" [routerLink]="patient.fhir_id.toLowerCase()">
              <td>{{ patient.username }}</td>
              <td>{{ getAgeGroup(patient.ageGroup)}}</td>
              <td>{{ patient.startDate | date}}</td>
              <td>{{ patient.endDate | date}}</td>
            </tr>
          </table>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
