import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IChemoBlockDay } from './chemo-block-day.model';
import * as ChemoBlockDayActions from './chemo-block-day.actions';
import { ChemoBlockDayNumber, ChemoBlockNumber } from '../../models/types';
import subDays from 'date-fns/subDays';
import { addDays, format } from 'date-fns';
export const chemoBlockDaysFeatureKey = 'chemoBlockDays';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChemoBlockDaysState extends EntityState<IChemoBlockDay> {
  // additional entities state properties
}

const curDate = new Date();

export const adapter: EntityAdapter<IChemoBlockDay> = createEntityAdapter<IChemoBlockDay>();

const initialEntities: IChemoBlockDay[] = [
  {
    id: '1|1',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.One,
    date: format(subDays(curDate, 3), 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
  {
    id: '1|2',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.Two,
    date: format(subDays(curDate, 2), 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
  {
    id: '1|3',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.Three,
    date: format(subDays(curDate, 1), 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
  {
    id: '1|4',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.Four,
    date: format(curDate, 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
  {
    id: '1|5',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.Five,
    date: format(addDays(curDate, 1), 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
  {
    id: '1|6',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.Six,
    date: format(addDays(curDate, 2), 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
  {
    id: '1|7',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.Seven,
    date: format(addDays(curDate, 3), 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
  {
    id: '1|8',
    chemoBlockNumber: ChemoBlockNumber.One,
    chemoBlockDayNumber: ChemoBlockDayNumber.Eight,
    date: format(addDays(curDate, 4), 'yyyy-MM-dd'),
    vomitRegistrationLocked: false,
    medicineRegistrationLocked: false,
  },
];

const initialEntitiesObj = initialEntities.reduce((prev, cur) => {
  prev[cur.id] = cur;
  return prev;
}, {});

const zeroState = adapter.getInitialState({});

export const initialState: ChemoBlockDaysState = {
  ...zeroState,
  ids: [
    ...(zeroState.ids as string[]),
    ...initialEntities.map((elem) => elem.id as string),
  ],
  entities: {
    ...zeroState.entities,
    ...initialEntitiesObj,
  },
};

export const chemoBlockDaysReducer = createReducer(
  initialState,
  on(ChemoBlockDayActions.addChemoBlockDay, (state, action) =>
    adapter.addOne(action.chemoBlockDay, state)
  ),
  on(ChemoBlockDayActions.upsertChemoBlockDay, (state, action) =>
    adapter.upsertOne(action.chemoBlockDay, state)
  ),
  on(ChemoBlockDayActions.addChemoBlockDays, (state, action) =>
    adapter.addMany(action.chemoBlockDays, state)
  ),
  on(ChemoBlockDayActions.upsertChemoBlockDays, (state, action) =>
    adapter.upsertMany(action.chemoBlockDays, state)
  ),
  on(ChemoBlockDayActions.updateChemoBlockDay, (state, action) =>
    adapter.updateOne(action.chemoBlockDay, state)
  ),
  on(ChemoBlockDayActions.updateChemoBlockDays, (state, action) =>
    adapter.updateMany(action.chemoBlockDays, state)
  ),
  on(ChemoBlockDayActions.deleteChemoBlockDay, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ChemoBlockDayActions.deleteChemoBlockDays, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ChemoBlockDayActions.loadChemoBlockDays, (state, action) =>
    adapter.setAll(action.chemoBlockDays, state)
  ),
  on(ChemoBlockDayActions.clearChemoBlockDays, (state) =>
    adapter.removeAll(state)
  ),
  on(ChemoBlockDayActions.resetChemoBlockDays, (state) => initialState)
);

export const selectChemoBlockDayState = createFeatureSelector<ChemoBlockDaysState>(
  chemoBlockDaysFeatureKey
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectChemoBlockDayState);

export const selectChemoBlockDayById = (id) =>
  createSelector(selectEntities, (days) => days[id] ?? null);
