import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { catchError, map } from 'rxjs';

/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { EpenatAppService } from '../services/app.service';
import { HestiaFhirUsersService } from '@hestia/ngx-fhir';
import { HestiaUserType } from '@hestia/ngx-types';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'epenat-definition-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar color="light">
        <ion-title color="primary">Add patient</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" (click)="dismissModal()">
            <ion-icon slot="icon-only" name="chevron-down-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <form [formGroup]="patientCreationForm">
        <ion-list lines="full">
          <ion-item>
            <ion-label position="stacked">Username</ion-label>
            <ion-input
              formControlName="username"
              type="text"
              placeholder="Enter username"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Password</ion-label>
            <ion-input
              formControlName="password"
              type="text"
              placeholder="Enter password"
            ></ion-input>
            <ion-button
              slot="end"
              color="primary"
              (click)="generatePassword()"
              fill="clear"
            >
              Generate
            </ion-button>
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Age Group</ion-label>
            <ion-select formControlName="ageGroup" placeholder="Not selected">
              <ion-select-option value="FOUR_TO_EIGHT">
                4 to less than 8 years
              </ion-select-option>
              <ion-select-option value="NINE_TO_EIGHTEEN">
                8 to less than 18 years
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-label>Start Date</ion-label>

            <ion-datetime-button
              datetime="startDate"
              slot="end"
            ></ion-datetime-button>

            <ion-popover [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  formControlName="startDate"
                  id="startDate"
                  presentation="date"
                ></ion-datetime>
              </ng-template>
            </ion-popover>
          </ion-item>

          <ion-item>
            <ion-label>End Date</ion-label>

            <ion-datetime-button
              datetime="endDate"
              slot="end"
            ></ion-datetime-button>

            <ion-popover [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  formControlName="endDate"
                  id="endDate"
                  [min]="patientCreationForm.value.startDate"
                  presentation="date"
                ></ion-datetime>
              </ng-template>
            </ion-popover>
          </ion-item>
        </ion-list>

        <ion-button
          class="ion-margin"
          expand="block"
          shape="round"
          [disabled]="!patientCreationForm.valid"
          (click)="submitCredentials()"
        >
          Done
        </ion-button>
      </form>
    </ion-content>
  `,
  styles: [
    `
      ion-datetime-button {
        margin: 16px 0;
      }
    `,
  ],
})
export class PatientFormComponent {
  patientCreationForm = new FormGroup({
    username: new FormControl(null, [
      Validators.minLength(3),
      Validators.required,
    ]),
    password: new FormControl(null, [
      Validators.minLength(5),
      Validators.required,
    ]),
    ageGroup: new FormControl(null, [
      Validators.minLength(5),
      Validators.required,
    ]),
    startDate: new FormControl(new Date().toISOString().split('T')[0], [
      Validators.required,
    ]),
    endDate: new FormControl(new Date().toISOString().split('T')[0], [
      Validators.required,
    ]),
  });

  constructor(
    public core: EpenatAppService,
    private fhirUsers: HestiaFhirUsersService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {}

  generatePassword() {
    const value = Math.random().toString(36).slice(-8);

    this.patientCreationForm.patchValue({
      password: value,
    });
  }

  dismissModal() {
    this.modalController.dismiss(null, 'cancel');
  }

  submitCredentials() {
    const payLoad = {
      birthdate: new Date().toISOString(),
      diagnosis: 'Unknown',
      email: `${this.patientCreationForm.value.username}@penat.app`,
      fullname: this.patientCreationForm.value.username,
      gender: 'female',
      password: this.patientCreationForm.value.password,
      social_security_number: Math.random().toString().slice(2, 12),
      user_type: HestiaUserType.Patient,
      username: this.patientCreationForm.value.username,
      properties: {
        ageGroup: this.patientCreationForm.value.ageGroup,
        startDate: this.patientCreationForm.value.startDate,
        endDate: this.patientCreationForm.value.endDate,
      },
    };

    return this.fhirUsers
      .createUser(payLoad)
      .pipe(
        map(() => {
          this.modalController.dismiss();
        }),
        catchError(async (error) =>
          this.presentError(error.error.error.errors[0].message)
        )
      )
      .subscribe();
  }

  async presentError(message: any) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
