<ion-header class="ion-no-border" collapse="fade">
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-back-button color="primary"></ion-back-button>
    </ion-buttons>
    <ion-title>Privacy Policy</ion-title>
    <ion-buttons slot="end">
      <ion-menu-button color="primary"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col [size]="12">
        <ion-card>
          <ion-card-header>
            <ion-card-title>About PeNAT</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>
              The Pediatric Nausea Assessment Tool (PeNAT) allows children and
              teens 4 to 18 years old receiving cancer treatments to log
              information about their nausea and vomiting symptoms.
            </p>

            <p>
              Caregivers can enter information into the app for their child but
              only children and teens can decide if they are nauseated and how
              bad their nausea is.
            </p>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col [size]="12">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Privacy Policy</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>
              We are committed to protecting the privacy and confidentiality of
              PeNAT app users.
            </p>
            <p>
              Access to PeNAT is provided only to users 4 to 18 years old who
              have consented or assented (with consent of legal guardian) to
              participate in a research study. These users have either agreed to
              use the app on a device provided by the researchers or to download
              the app to their, or their guardian’s, personal device. A unique
              user code is given to each user by the researchers and access to
              PeNAT is granted only for the expected duration of the research.
            </p>
            <p>
              To protect against theft, loss and unauthorized access, copying,
              modification, use disclosure and disposal, all data entered into
              the app are stored in a firewall-protected server at SickKids and
              accessed by authorized and privacy-trained researchers.
            </p>
            <p>
              Study data are subject to the data privacy requirements in place
              at SickKids, Canada
              (https://www.sickkids.ca/en/patients-visitors/privacy/) and other
              institutions where a study using the app is open. When the app is
              in use, data will be stored temporarily in a local cache on your
              device.
            </p>
            <p>
              Android users are reminded that they are subject to Google’s
              policies regarding information collection and use
              (https://families.google.com/familylink/privacy/child-policy/).
            </p>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card>
          <ion-card-content>
            <p>
              PeNAT is developed by Sick Kids, Stanford University Hospital and
              ZiteLab ApS. Contact: contact@zitelab.dk
            </p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
