import {
  HestiaAppUse,
  HestiaAuthMethod,
  HestiaSupportedLanguage,
  IHestiaCoreConfig,
  IHestiaSentryConfig,
  OptionalExceptFor,
} from '@hestia/ngx-types';

import packageJson from '../../../../package.json';

export const EPENAT_SENTRY_DSN =
  'https://d1f71412e950447785890f87ba56bc26@sentry.zitelab.eu/36';

export interface IEpenatCoreConfig extends IHestiaCoreConfig {
  testUsername?: string;
  testPassword?: string;
  syncStateToStorage: boolean;
  isAuthenticatedGuardFailureRoute: string;
  isUnauthenticatedGuardFailureRoute: string;
}

export class EpenatEnv implements IEpenatCoreConfig {
  production: boolean;
  debugMode: boolean;
  envName: string;
  apiUrl: string;
  availableLangs: HestiaSupportedLanguage[];
  defaultLang: HestiaSupportedLanguage;
  appBuiltFor: HestiaAppUse;
  defaultAuthMethod: HestiaAuthMethod;
  syncStateToStorage: boolean;
  syncStorageKey: string;
  syncHydratedKey: string;
  release: string;
  sentry: IHestiaSentryConfig;
  testUsername?: string;
  testPassword?: string;
  isAuthenticatedGuardFailureRoute: string;
  isUnauthenticatedGuardFailureRoute: string;
  constructor(
    config: OptionalExceptFor<
      IEpenatCoreConfig,
      'production' | 'debugMode' | 'envName' | 'sentry' | 'appBuiltFor'
    >
  ) {
    this.production = config.production;
    this.debugMode = config.debugMode;
    this.envName = config.envName;
    this.apiUrl = config.apiUrl;
    this.availableLangs = config.availableLangs ?? ['en-US', 'da-DK'];
    this.defaultLang = config.defaultLang ?? 'en-US';
    this.appBuiltFor = config.appBuiltFor;
    this.defaultAuthMethod =
      config.defaultAuthMethod ?? 'USERNAME_AND_PASSWORD';
    this.syncStateToStorage = config?.syncStateToStorage ?? true;
    this.syncStorageKey =
      config.syncStorageKey ?? 'zitelab.eu.epenat.patient.app';
    this.syncHydratedKey = config.syncHydratedKey ?? 'hydrated';
    this.release = config.release ?? packageJson.version;
    this.sentry = config.sentry;
    this.testUsername = config?.testUsername;
    this.testPassword = config?.testPassword;
    this.isAuthenticatedGuardFailureRoute =
      config.isAuthenticatedGuardFailureRoute;
    this.isUnauthenticatedGuardFailureRoute =
      config.isUnauthenticatedGuardFailureRoute;
  }
}
