/* eslint-disable ngrx/no-typed-global-store */

/* eslint-disable ngrx/avoid-dispatching-multiple-actions-sequentially */
import { isToday, isYesterday } from 'date-fns';
import { Questionnaire } from 'fhir/r4';
import OneSignal from 'onesignal-cordova-plugin';
import { v4 } from 'uuid';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';

import { AlertController, NavController } from '@ionic/angular';

import { Capacitor } from '@capacitor/core';

import { firstValueFrom } from 'rxjs';

import { Store } from '@ngrx/store';

import { CORE_HESTIA_CONFIG } from '@hestia/ngx-common';
import { HestiaFhirResourcesService, fhirResourceActions, selectQnrResponses } from '@hestia/ngx-fhir';
import {
  HestiaQnrFormSessionService,
  initSession,
  isQnrAdaptive,
  qnrFormSessionActions,
  QuestionnaireResponseService,
} from '@hestia/ngx-fhir-qnr-forms';
import { HestiaFhirResourceTypeName, IHestiaCoreConfig } from '@hestia/ngx-types';
import { HestiaUserFacade } from '@hestia/ngx-user';

import { EpenatQnrId, EpenatRoutes } from '../models/types';
import { IEpenatAppState } from '../store';
import { IChemoBlockDay } from '../store/+chemo-block-day/chemo-block-day.model';
import { epenatSpecificActions } from '../store/+state/epenat-specific.actions';
import { IEpenatSpecificState } from '../store/+state/epenat-specific.reducer';
import { selectEpenatSpecificState } from '../store/+state/epenat-specific.selectors';

@Injectable({ providedIn: 'root' })
export class EpenatAppService {
  firstName: string;
  routes = EpenatRoutes;
  coreState: IEpenatSpecificState;
  curChemoDay: IChemoBlockDay;
  apiUrl: string;

  qnrResponses$ = this.store.select(selectQnrResponses);
  constructor(
    private userFacade: HestiaUserFacade,
    private store: Store<IEpenatAppState>,
    private fhirService: HestiaFhirResourcesService,
    private navController: NavController,
    private alertController: AlertController,
    private qnrResponseService: QuestionnaireResponseService,
    private sessionService: HestiaQnrFormSessionService,
    private zone: NgZone,
    @Inject(CORE_HESTIA_CONFIG) private hestiaCoreConfig: IHestiaCoreConfig,
    private http: HttpClient
  ) {
    this.store
      .select(selectEpenatSpecificState)
      // eslint-disable-next-line ngrx/no-store-subscription
      .subscribe((state) => (this.coreState = state));
    this.apiUrl = this.hestiaCoreConfig.apiUrl;
  }

  isDateFine = (date) => isToday(new Date(date)) || isYesterday(new Date(date));

  async startDefinitionsQuestionnaire() {
    await this.initQuestionnaire({
      label: 'Definitions Questionnaire',
      resourceId: EpenatQnrId.EPENAT_NAUSEA_WORDS_4_TO_18,
    }).then((sessionId) => {
      this.navController.navigateForward(`/${EpenatRoutes.QuestionnairePage}/${sessionId}`);
    });
  }

  async handleLogout(navigateToRoot: boolean) {
    this.store.dispatch(qnrFormSessionActions.clearSessions());
    this.store.dispatch(fhirResourceActions.clearFhirResources());
    this.store.dispatch(epenatSpecificActions.resetState());
    this.fhirService.inflightFetchIds = new Set();
    if (navigateToRoot) {
      this.zone.run(() => this.navController.navigateRoot(['/']));
    }
  }

  async handleLoginRedirect() {
    const userState = await firstValueFrom(this.userFacade.userState$);
    if (userState.userType === 'Practitioner') {
      this.zone.run(() => this.navController.navigateRoot(['/admin']));
    } else {
      if (Capacitor.isNativePlatform()) {
        OneSignal.login(userState.fhirId);
      }
      this.navController.navigateRoot(`/${EpenatRoutes.TabsPage}`);
    }
  }

  async deleteResponse(id) {
    const url = `${this.apiUrl}/@fhir/QuestionnaireResponse/${id}`;
    return this.http.delete<boolean>(url).subscribe((result) => {
      this.store.dispatch(fhirResourceActions.deleteFhirResource({ id }));
      return result;
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Definitions needed!',
      message:
        'Before you can use the PeNAT app, please enter the words for nausea and vomiting that you use in your family.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Answer now',
          handler: async () => {
            const navTransition = alert.dismiss();
            await this.initQuestionnaire({
              label: 'Definition',
              resourceId: EpenatQnrId.EPENAT_NAUSEA_WORDS_4_TO_18,
            }).then((sessionId) => {
              this.navController.navigateForward(`/${EpenatRoutes.QuestionnairePage}/${sessionId}`);

              navTransition.then(() => {
                alert.dismiss();
              });
            });
          },
        },
      ],
    });

    await alert.present();
  }

  async initQuestionnaire(_props: {
    label: string;
    resourceId: string;
    sId?: string;
    fhirId?: string;
    qnrResponse?: null | fhir4.QuestionnaireResponse;
    values?: Record<string, unknown>;
  }): Promise<string> {
    const userState = await firstValueFrom(this.userFacade.userState$);
    const sessionId = _props.sId ?? v4();
    const questionnaire: Questionnaire = await this.fhirService.resolveReference({
      resourceType: HestiaFhirResourceTypeName.Questionnaire,
      resourceId: _props.resourceId,
    });

    const values = this.qnrResponseService.getFormValuesFromQuestionnaireResponse(_props.qnrResponse);

    this.store.dispatch(
      initSession({
        session: {
          id: sessionId,
          displayTitle: _props.label,
          questionnaireId: questionnaire.id,
          carePlanId: null,
          values: _props.qnrResponse ? values : _props.values,
          carePlanStatus: 'ready',
          activityStatus: 'null',
          activityCode: null,
          patientId: userState.fhirId ?? _props.fhirId,
          renderType: 'page',
          metadata: {},
          localVariables: {
            familyWordForVomiting: {
              value: this.coreState.wordsForVomiting,
              global: true,
            },
            familyWordForNausea: {
              value: this.coreState.wordsForNausea,
              global: true,
            },
          },
          subjectRef: { reference: userState.fhirId ?? _props.fhirId },
          authorRef: { reference: userState.fhirId ?? _props.fhirId },
          sourceRef: { reference: userState.fhirId ?? _props.fhirId },
          isAdaptive: isQnrAdaptive(questionnaire),
          questionnaireResponse: _props.qnrResponse ?? null,
          doesQnrResponseExistOnServer: _props.qnrResponse ? true : false,
          curLinkIdPath: null,
        },
      })
    );
    return sessionId;
  }

  responseItemsToJson(response: fhir4.QuestionnaireResponse) {
    const result = {};
    for (const index in response.item) {
      if ({}.hasOwnProperty.call(response.item, index)) {
        const tt = response.item[index];
        let val = '';
        if (tt?.answer?.length > 0) {
          if ({}.hasOwnProperty.call(tt.answer[0], 'valueString')) {
            val = tt.answer[0]?.valueString;
          }
          if ({}.hasOwnProperty.call(tt.answer[0], 'valueCoding')) {
            val = tt.answer[0]?.valueCoding.code;
          }
        }
        result[tt.linkId] = val;
      }
    }
    return result;
  }
  parseFamilyWord(result: fhir4.QuestionnaireResponse[]) {
    let wordsForNausea = '';
    let wordsForVomiting = '';
    let authored = '';
    if (result.length >= 1) {
      const definition = result[0];
      authored = definition.authored;

      const values = this.responseItemsToJson(definition);

      wordsForVomiting = values['wordsForVomiting'];
      const everVomitted = values['everVomited'];
      const almostVomited = values['almostVomited'];
      if (everVomitted === 'YES') {
        wordsForNausea = values['wordsForNauseaHasVomited'];
      } else {
        if (almostVomited === 'YES') {
          wordsForNausea = values['wordsForNauseaOnlyAlmostVomited'];
        }
        if (almostVomited === 'NO') {
          wordsForNausea = values['wordsForNauseaNoExperience'];
        }
      }
    }

    return {
      wordsForNausea: wordsForNausea,
      wordsForVomiting: wordsForVomiting,
      authored: authored,
    };
  }
}
