import { EPENAT_SENTRY_DSN, EpenatEnv, IEpenatCoreConfig } from './epenat-env';

// For now just a copy of dev env
export const environment: IEpenatCoreConfig = new EpenatEnv({
  production: false,
  debugMode: true,
  envName: 'prod',
  // apiUrl: 'https://epenat-proxy.research.sickkids.ca/api/',
  apiUrl: 'https://epenat.ca/api/',
  appBuiltFor: 'DEV',
  isUnauthenticatedGuardFailureRoute: `/tabs`,
  isAuthenticatedGuardFailureRoute: `/`,
  sentry: { enabled: false, dsn: EPENAT_SENTRY_DSN },
  defaultAuthMethod: 'USERNAME_AND_PASSWORD',
  testUsername: '',
  testPassword: '',
  syncStateToStorage: true,
});
